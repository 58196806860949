<div
  class="footer"
  id="site-footer">
  <div class="footer__copyright">Copyright © Waterford.org</div>

  <a
    class="footer__license-agreement"
    href="https://www.waterford.org/eula/"
    rel="noopener noreferrer"
    target="_blank">
    {{ 'License Agreement' | translate }}
  </a>

  <a
    class="footer__privacy-policy"
    href="https://www.waterford.org/privacy-policy/"
    rel="noopener noreferrer"
    target="_blank">
    {{ 'Privacy Policy' | translate }}
  </a>
</div>
