import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { State, TabDisplayInterface, User } from '@wam/shared';
import * as fromClaims from '@app/claims/state/claims.selectors';
import { authenticationSelectors } from '@wam/authentication';
import { isParent } from '@app/authentication/user.data';

@Injectable({
  providedIn: 'root',
})
export class ParentWithClaimsTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of<boolean>(false);
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      switchMap((user: User) => (isParent(user) ? this.hasClaims() : of(false))),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private hasClaims(): Observable<boolean> {
    return this.store.pipe(
      select(fromClaims.getClaims),
      map((claims) => claims.length > 0),
      takeUntil(this.destroyed$),
    );
  }
}
