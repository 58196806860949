import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogoutConfirmDialogService } from '@app/shared/logout-confirm-dialog/logout-confirm-dialog.service';
import { ChildrenOutletContexts, Router } from '@angular/router';
import {
  animate,
  group,
  keyframes,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Claim, State, User, UserType } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import * as fromLanding from '@app/landing/state/landing.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { authenticationSelectors, logOut } from '@wam/authentication';
import { Subject } from 'rxjs';
import * as landingActions from '@app/landing/state/landing.actions';

const forward = [
  sequence([
    group([style({ transform: 'translateX(calc(100% + 15px))' }), animate('250ms ease-in-out')]),
    animate(
      '350ms ease-in-out',
      keyframes([
        style({ transform: 'translateX(-15px)' }),
        style({ transform: 'translateX(7px)' }),
        style({ transform: 'translateX(-7px)' }),
        style({ transform: 'translateX(0)' }),
      ]),
    ),
  ]),
];

const backward = [
  sequence([
    group([style({ transform: 'translateX(calc(-100% - 15px))' }), animate('250ms ease-in-out')]),
    animate(
      '350ms ease-in-out',
      keyframes([
        style({ transform: 'translateX(15px)' }),
        style({ transform: 'translateX(-7px)' }),
        style({ transform: 'translateX(7px)' }),
        style({ transform: 'translateX(0)' }),
      ]),
    ),
  ]),
];

export const routeAnimations = trigger('routeAnimations', [
  transition('Home => Courses', forward),
  transition('Courses => Home', backward),
  transition('Home => Assigned', forward),
  transition('Assigned => Home', backward),
]);

@Component({
  selector: 'student-redesign-layout',
  templateUrl: './student-redesign-layout.component.html',
  styleUrls: ['./student-redesign-layout.component.scss'],
  animations: [routeAnimations],
})
export class StudentRedesignLayoutComponent implements OnInit, OnDestroy {
  private selectedClaim: Claim;
  private destroyed$ = new Subject<void>();

  loggedInUser: User;

  constructor(
    private store: Store<State>,
    private router: Router,
    private contexts: ChildrenOutletContexts,
    private logoutConfirmDialogService: LogoutConfirmDialogService,
  ) {}

  ngOnInit(): void {
    this.setLoggedInUser();
    this.setSelectedClaim();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openConfirmLogoutDialog(): void {
    this.logoutConfirmDialogService
      .openLogoutConfirmDialog()
      .afterClosed()
      .subscribe((confirmation) => {
        if (confirmation) {
          this.logOut();
        }
      });
  }

  logOut(): void {
    if (this.loggedInUser.type === UserType.UPSTART) {
      this.store.dispatch(landingActions.clearSelectedClaim());
      this.router.navigateByUrl('/landing');
    } else {
      this.store.dispatch(logOut());
    }
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  get preferredName(): string | undefined {
    if (this.selectedClaim) {
      return undefined;
    }
    return this.loggedInUser?.preferredName;
  }

  get firstName(): string {
    if (this.selectedClaim) {
      return this.selectedClaim.resourceName.split(' ')[0];
    }
    return this.loggedInUser?.name.split(' ')[0];
  }

  private setSelectedClaim(): void {
    this.store
      .pipe(
        select(fromLanding.getSelectedClaim),
        filter((selectedClaim) => !isNil(selectedClaim)),
        takeUntil(this.destroyed$),
      )
      .subscribe((selectedClaim: Claim) => (this.selectedClaim = selectedClaim));
  }

  setLoggedInUser(): void {
    this.store
      .pipe(
        select(authenticationSelectors.getCurrentUser),
        filter((user) => !isNil(user)),
        takeUntil(this.destroyed$),
      )
      .subscribe((loggedInUser: User) => (this.loggedInUser = loggedInUser));
  }
}
