<ng-progress [id]="PROGRESS_ID"></ng-progress>
<notifications></notifications>
<ng-container *ngIf="showScroll$ | async">
  <scroll-to-top (scrollToTop)="onScrollToTop()"></scroll-to-top>
</ng-container>

@switch (layout$ | async) {
  @case (Layouts.DEFAULT_LAYOUT) {
    <default-layout></default-layout>
  }
  @case (Layouts.STUDENT_REDESIGN_LAYOUT) {
    <student-redesign-layout></student-redesign-layout>
  }
  @case (Layouts.UPSTART_LOGIN_LAYOUT) {
    <upstart-login-layout class="upstart-layout"></upstart-login-layout>
  }
  @case (Layouts.UPSTART_NETFLIX_LAYOUT) {
    <upstart-netflix-layout class="upstart-layout"></upstart-netflix-layout>
  }
  @default {
    <div>
      <router-outlet></router-outlet>
    </div>
  }
}
