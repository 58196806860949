import { settingsSelectors, State, TabDisplayInterface, UserType } from '@wam/shared';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ParentWithClaimsTabDisplayService } from './parent-with-claims-tab-display.service';

@Injectable({
  providedIn: 'root',
})
export class FeedDataTabDisplayService implements TabDisplayInterface {
  isParentUser$ = this.store.pipe(
    select(settingsSelectors.getUserType),
    map((userType) => userType === UserType.PARENT),
  );

  constructor(
    private store: Store<State>,
    private parentWithClaimsTabDisplayService: ParentWithClaimsTabDisplayService,
  ) {}

  isFirst(): Observable<boolean> {
    return this.parentWithClaimsTabDisplayService.isFirst();
  }

  shouldShow(): Observable<boolean> {
    return combineLatest([
      this.parentWithClaimsTabDisplayService.shouldShow(),
      this.isParentUser$,
    ]).pipe(
      map(([shouldShow, isParentUser]) => {
        return shouldShow && isParentUser;
      }),
    );
  }
}
