import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { settingsSelectors, State, UserType } from '@wam/shared';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { EulaService } from '../eula/eula.service';
import { LandingService } from '@app/landing/landing.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private store: Store<State>,
    private router: Router,
    private eulaService: EulaService,
    private landingService: LandingService,
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(
        select(authenticationSelectors.getCurrentUser),
        map((user) => !!user),
      ),
      this.eulaService.mustShowEula(),
      this.store.pipe(select(settingsSelectors.isUpstartLoginEnabled)),
      this.store.pipe(select(settingsSelectors.getUserType)),
      this.landingService.mustShowLanding(),
    ]).pipe(
      tap(
        ([isLoggedIn, mustShowEula, isUpstartLoginEnabled, settingsUserType, mustShowLanding]) => {
          if (!isLoggedIn) {
            if (settingsUserType === UserType.UPSTART && isUpstartLoginEnabled) {
              if (this.router.routerState.snapshot.url.includes('user-login')) {
                return;
              }
              this.router.navigateByUrl(`/user-login`);
            } else {
              this.router.navigateByUrl(`/login`);
            }
          } else if (mustShowEula) {
            this.router.navigateByUrl('/eula');
          } else if (mustShowLanding) {
            this.router.navigateByUrl('/landing');
          }
        },
      ),
      map(([isLoggedIn]) => isLoggedIn),
      take(1),
    );
  }

  canLoad(): Observable<boolean> {
    return this.canActivate();
  }
}
