<div class="content">
  <div class="text">{{ 'Do you want to log out?' | translate }}</div>
  <div class="buttons">
    <button
      class="buttons__button buttons__button--yes"
      [mat-dialog-close]="true">
      <img
        src="assets/icons/icon-accept.svg"
        alt="Accept" />
    </button>
    <button
      class="buttons__button buttons__button--no"
      mat-dialog-close>
      <img
        src="assets/icons/icon-decline.svg"
        alt="Decline" />
    </button>
  </div>
</div>
