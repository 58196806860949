import { Component } from '@angular/core';
import { Icon } from '@wam/icons';
import { Router } from '@angular/router';

@Component({
  selector: 'upstart-netflix-layout',
  templateUrl: './upstart-netflix-layout.component.html',
  styleUrls: ['./upstart-netflix-layout.component.scss'],
})
export class UpstartNetflixLayoutComponent {
  protected readonly Icon = Icon;

  constructor(private router: Router) {}

  logOut(): void {
    this.router.navigateByUrl('/logout');
  }
}
