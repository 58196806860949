import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { configurationSelectors, State, UserRole } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { saveUserConfiguration } from '@app/configuration/state/configuration.actions';
import { map, take } from 'rxjs/operators';

const HELP_URL = 'https://lighthouse.helpdocs.io/load';

declare let Lighthouse: {
  show: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class HelpWidgetService {
  constructor(private store: Store<State>) {}

  show(): void {
    const widget = document.getElementById('lighthouse');
    if (widget) {
      widget.style.display = 'block';
    } else {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `${HELP_URL}?t=${new Date().getTime()}`;
      script.id = 'lighthouse-helpdocs';
      document.head.appendChild(script);
    }
  }

  hide(): void {
    const widget = document.getElementById('lighthouse');
    if (widget) {
      widget.style.display = 'none';
    }
  }

  shouldShowAnimation(): boolean {
    return !localStorage.hasOwnProperty('supportWidgetAnimation');
  }

  storeAnimationProperty(): void {
    localStorage.setItem('supportWidgetAnimation', 'hide');
  }

  private hasSeenImportHelp(): Observable<boolean> {
    return this.store.pipe(
      select(configurationSelectors.getUserConfiguration<boolean>('hasSeenImportHelp')),
    );
  }

  private isReadonly(): Observable<boolean> {
    return this.store.pipe(select(authenticationSelectors.isReadOnly));
  }

  private isTeacher(): Observable<boolean> {
    return this.store
      .pipe(select(authenticationSelectors.getUserRoles))
      .pipe(
        map(
          (roles) =>
            !roles.includes(UserRole.DISTRICT_ADMIN) &&
            !roles.includes(UserRole.SCHOOL_ADMIN) &&
            !roles.includes(UserRole.CLASS_ADMIN),
        ),
      );
  }

  private markImportHelpAsSeen(): void {
    this.store.dispatch(saveUserConfiguration({ key: 'hasSeenImportHelp', value: true }));
  }

  autoShow(): void {
    combineLatest([this.hasSeenImportHelp(), this.isReadonly(), this.isTeacher()])
      .pipe(take(1))
      .subscribe(([hasSeenImportHelp, isReadOnly, isTeacher]) => {
        if (!isReadOnly && !isTeacher && !hasSeenImportHelp) {
          setTimeout(() => {
            Lighthouse.show();
            this.markImportHelpAsSeen();
          }, 1000); // Delay to allow the widget to load
        }
      });
  }
}
