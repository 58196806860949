import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { State, UserRole, UserType } from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { Tabs } from '@app/shared/tabs.enum';
import { isNil } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

declare global {
  interface Window {
    tags: string[];
  }
}

const typeMap = {
  [UserType.UNKNOWN]: 'unknown',
  [UserType.STUDENT]: 'my',
  [UserType.TEACHER]: 'teacher',
  [UserType.PARENT]: 'mentor',
  [UserType.ADMIN]: 'admin',
  [UserType.UPSTART]: 'upstart',
};

@Injectable({
  providedIn: 'root',
})
export class HelpdocsService {
  readonly currentTags$: Observable<string[]>;

  private currentRoute$: Observable<string> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => (event as NavigationEnd).url.split('/')[1]),
    startWith(this.window.location.pathname.substring(1)),
    distinctUntilChanged(),
  );

  private application$: Observable<string> = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    filter((user) => !isNil(user)),
    map(({ type }) => typeMap[type]),
  );

  private currentUserRole$: Observable<UserRole[]> = this.store.pipe(
    select(authenticationSelectors.getUserRoles),
    map((roles) => (roles ? roles : [])),
  );

  private language$: Observable<string> = this.translateService.onLangChange.pipe(
    map(({ lang }) => lang),
    startWith(this.translateService.currentLang),
  );

  private currentTagsDispatcher = new ReplaySubject<string[]>(1);

  constructor(
    private router: Router,
    private store: Store<State>,
    private translateService: TranslateService,
    @Inject('window') private window: Window,
  ) {
    this.currentTags$ = this.currentTagsDispatcher.asObservable();

    combineLatest([this.application$, this.currentUserRole$, this.currentRoute$, this.language$])
      .pipe(
        map(([app, roles, route, lang]) => this.buildTags(app, roles, route, lang)),
        tap((tags) => (window.tags = tags)),
      )
      .subscribe((tags) => this.currentTagsDispatcher.next(tags));
  }

  getTagsForTab(tab: Tabs): Observable<string[]> {
    const location = this.router.config.find(({ data }) => data.tab === tab);
    const route = location.path;
    const language = this.translateService.currentLang;
    return combineLatest([this.application$, this.currentUserRole$]).pipe(
      map(([application, roles]) => this.buildTags(application, roles, route, language)),
      take(1),
    );
  }

  private buildTags(
    application: string,
    roles: string[],
    location: string,
    language: string,
  ): string[] {
    const rolePool: string[] = [];
    rolePool.push(...roles);
    if (roles.length === 0) {
      rolePool.push(application);
    }
    rolePool.push('');
    const lang = language === 'en' ? '' : `${language}--`;
    return rolePool.map((role) => `${lang}${application}--${role}--${location}`);
  }
}
