import { TabDisplayInterface } from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileTabDisplayService implements TabDisplayInterface {
  isFirst(): Observable<boolean> {
    return of<boolean>(false);
  }

  shouldShow(): Observable<boolean> {
    return of<boolean>(true);
  }
}
