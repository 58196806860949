import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnrollmentState } from './enrollment.state';

const getSettingsFeatureState = createFeatureSelector<EnrollmentState>('enrollment');

const getSteps = createSelector(getSettingsFeatureState, (state) => state.steps);

const getForms = createSelector(getSettingsFeatureState, (state) => state.forms);

const getEvents = createSelector(getSettingsFeatureState, (state) => state.events);

const getError = createSelector(getSettingsFeatureState, (state) => state.error);

export const enrollmentSelectors = {
  getSteps,
  getForms,
  getEvents,
  getError,
};
