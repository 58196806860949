import { settingsSelectors, State, TabDisplayInterface, UserType } from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramsTabDisplayService implements TabDisplayInterface {
  isUpstartUser$: Observable<boolean> = this.store.pipe(
    select(settingsSelectors.getUserType),
    map((userType) => userType === UserType.UPSTART),
  );

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return this.isUpstartUser$.pipe(map((isUpstartUser) => isUpstartUser));
  }
}
