import { State, TabDisplayInterface, UserType } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';
import * as fromLanding from '@app/landing/state/landing.selectors';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class NewStudentHomeTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isClaimSelected(): Observable<boolean> {
    return this.store
      .pipe(select(fromLanding.getSelectedClaim))
      .pipe(map((claim) => !isNil(claim)));
  }

  isFirst(): Observable<boolean> {
    const isFirst = true;

    return this.shouldShow().pipe(
      map((shouldShow: boolean) => shouldShow && isFirst),
      takeUntil(this.destroyed$),
    );
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      withLatestFrom(this.isClaimSelected()),
      map(([user, claimSelected]) => claimSelected || user?.type === UserType.STUDENT),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
