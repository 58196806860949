import { State, TabDisplayInterface, User, UserType } from '@wam/shared';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';

@Injectable({
  providedIn: 'root',
})
export class StudentHomeTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    const isFirst = true;

    return this.shouldShow().pipe(
      map((shouldShow: boolean) => shouldShow && isFirst),
      takeUntil(this.destroyed$),
    );
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(authenticationSelectors.getCurrentUser),
      map((user: User) => user?.type === UserType.STUDENT),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
