{
  "name": "reading-academy",
  "version": "3.39.0",
  "engine-strict": true,
  "engines": {
    "node": "^20",
    "npm": "^10.0.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-prod": "ng build --configuration production",
    "test": "ng test",
    "clear-jest-cache": "jest --clearCache",
    "lint": "ng lint",
    "lint-quiet": "ng lint --quiet",
    "lint-fix": "ng lint --fix",
    "postinstall": "node postinstall.js",
    "translate": "ngx-translate-extract --input ./src --output ./src/assets/i18n/es.json ./src/assets/i18n/en.json ./src/assets/i18n/ro.json --sort --format json; node translate-i18n.js ro es",
    "translate:clean": "rm ./src/assets/i18n/*.json; npm run translate",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.1",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "17.3.1",
    "@angular/compiler": "17.3.1",
    "@angular/core": "17.3.1",
    "@angular/elements": "17.3.1",
    "@angular/forms": "17.3.1",
    "@angular/material": "17.3.1",
    "@angular/platform-browser": "17.3.1",
    "@angular/platform-browser-dynamic": "17.3.1",
    "@angular/router": "17.3.1",
    "@angular/service-worker": "17.3.1",
    "@ckeditor/ckeditor5-angular": "^7.0.1",
    "@colsen1991/ngx-translate-extract-marker": "^2.0.8",
    "@formio/angular": "7.0.0",
    "@kokotree-inc/angular-code-input": "1.6.3",
    "@kolkov/angular-editor": "2.1.0",
    "@livechat/widget-angular": "1.3.2",
    "@ngrx/effects": "17.1.1",
    "@ngrx/store": "17.1.1",
    "@ngrx/store-devtools": "17.1.1",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "@sentry/angular-ivy": "7.108.0",
    "@snowplow/browser-tracker": "^3.22.1",
    "@wap/batch": "0.0.60",
    "@wap/catalog-v5": "5.13.1",
    "@wap/configuration": "2.8.19",
    "@wap/enrollment": "1.1.7",
    "@wap/enrollment-v2": "2.3.80",
    "@wap/licensing": "3.2.173",
    "@wap/profile-picture": "^1.1.5",
    "@wap/reporting": "2.0.102",
    "@wap/sso-bridge": "1.1.0",
    "@wap/tenant": "2.2.35",
    "@wap/user-lookup": "2.0.61",
    "@wap/wel-event-bridge-interface": "2.0.23",
    "ajv": "^8.12.0",
    "angular-google-tag-manager": "1.9.0",
    "angulartics2": "^13.0.0",
    "audioService": "3.6.11",
    "aws-sdk": "2.1587.0",
    "ckeditor5-build-classic-waterford": "1.6.4",
    "d3": "^7.9.0",
    "date-fns": "3.6.0",
    "deep-object-diff": "1.1.9",
    "dom-to-image": "2.6.0",
    "file-saver": "2.0.5",
    "formiojs": "4.18.2",
    "html-to-image": "1.11.11",
    "jspdf": "2.5.1",
    "lodash-es": "4.17.21",
    "luxon": "^3.4.4",
    "ng-recaptcha": "13.2.1",
    "ngx-image-compress": "15.1.6",
    "ngx-infinite-scroll": "17.0.0",
    "ngx-progressbar": "^11.1.0",
    "ngx-scrollbar": "13.0.3",
    "qrcode": "1.5.3",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "url-search-params-polyfill": "8.2.5",
    "usa-states": "0.0.6",
    "uuidv4": "6.2.13",
    "zone.js": "0.14.4"
  },
  "devDependencies": {
    "@angular-builders/jest": "17.0.2",
    "@angular-devkit/build-angular": "17.3.2",
    "@angular-eslint/builder": "17.3.0",
    "@angular-eslint/eslint-plugin": "17.3.0",
    "@angular-eslint/eslint-plugin-template": "17.3.0",
    "@angular-eslint/schematics": "17.3.0",
    "@angular-eslint/template-parser": "17.3.0",
    "@angular/cli": "17.3.2",
    "@angular/compiler-cli": "17.3.1",
    "@angular/language-service": "17.3.1",
    "@faker-js/faker": "^8.4.1",
    "@google-cloud/translate": "8.1.0",
    "@testing-library/angular": "15.2.0",
    "@types/d3": "^7.4.3",
    "@types/jest": "29.5.12",
    "@types/lodash-es": "4.17.12",
    "@types/luxon": "^3.4.2",
    "@types/qrcode": "1.5.5",
    "@typescript-eslint/eslint-plugin": "^7.4.0",
    "@typescript-eslint/parser": "^7.4.0",
    "@vendure/ngx-translate-extract": "^9.1.1",
    "babel-jest": "^29.7.0",
    "dotenv": "16.4.5",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-jasmine": "4.1.3",
    "eslint-plugin-prettier": "5.1.3",
    "eslint-plugin-unused-imports": "3.1.0",
    "husky": "9.0.11",
    "jasmine-core": "5.1.2",
    "jasmine-marbles": "0.9.2",
    "jest": "29.7.0",
    "jest-canvas-mock": "2.5.2",
    "jest-jasmine2": "29.7.0",
    "jest-preset-angular": "14.0.3",
    "jest-sonar": "0.2.16",
    "jest-stare": "^2.5.1",
    "jest-when": "3.6.0",
    "lint-staged": "15.2.2",
    "ng-lint-staged": "12.0.4",
    "ng-packagr": "17.3.0",
    "prettier": "3.2.5",
    "timezone-mock": "1.3.6",
    "ts-mockito": "2.6.1",
    "typescript": "5.2.2"
  },
  "lint-staged": {
    "src/**/*.{ts,html}": [
      "ng-lint-staged lint --fix --"
    ]
  }
}
