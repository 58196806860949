import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogoutConfirmDialogComponent } from './logout-confirm-dialog.component';

@Injectable()
export class LogoutConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  openLogoutConfirmDialog(): MatDialogRef<LogoutConfirmDialogComponent> {
    return this.dialog.open(LogoutConfirmDialogComponent, {
      panelClass: 'new-dialog',
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
      autoFocus: false,
      width: '100%',
      maxWidth: '488px',
    });
  }
}
