import { Injectable } from '@angular/core';

/**
 * Access to the global window variable.
 */
declare const window: {
  [key: string]: { push: (arr: string[]) => void };
  new (): Window;
};

@Injectable({
  providedIn: 'root',
})
export class MatomoInjector {
  /**
   * Injects the Matomo tracker in the DOM.
   *
   * @param url URL of the Matomo instance to connect to.
   * @param id SiteId for this application/site.
   * @param [scriptUrl] Optional URL for the `piwik.js`/`matomo.js` script in case it is not at its default location.
   */
  init(url: string, id: number, scriptUrl?: string) {
    window._paq = window._paq || [];

    window._paq.push(['enableLinkTracking']);
    (() => {
      const u = url;
      window._paq.push(['setTrackerUrl', u + 'piwik.php']);
      window._paq.push(['setSiteId', id.toString()]);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = !!scriptUrl ? scriptUrl : u + 'piwik.js';
      s.parentNode.insertBefore(g, s);
    })();
  }
}
