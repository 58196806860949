import { TabDisplayInterface } from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParentWithClaimsTabDisplayService } from '@app/shared/parent-with-claims-tab-display.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardsTabDisplayService implements TabDisplayInterface {
  constructor(private parentWithClaimsTabDisplayService: ParentWithClaimsTabDisplayService) {}

  isFirst(): Observable<boolean> {
    return this.parentWithClaimsTabDisplayService.isFirst();
  }

  shouldShow(): Observable<boolean> {
    return this.parentWithClaimsTabDisplayService.shouldShow();
  }
}
