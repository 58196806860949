import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { EMPTY, Observable, Subject } from 'rxjs';
import {
  configurationSelectors,
  settingsSelectors,
  State,
  User,
  UserRole,
  UserType,
} from '@wam/shared';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors, getOrgCode } from '@wam/authentication';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  share,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { TabDisplayAggregatorService } from '@app/shared/tab-display-aggregator.service';
import { AuthenticationGuard } from '@app/authentication/authentication.guard';
import { TranslateService } from '@ngx-translate/core';
import { MessagesAndNotificationsTabDisplayService } from '@app/messages-and-notifications/messages-and-notifications-tab-display.service';
import { Theme, ThemingService } from '@wam/theming';
import { SidenavService } from '@app/shared/sidenav.service';
import { EnrollmentWizardDialogService } from '@app/programs/enrollment-dialogs/enrollment-wizard-dialog.service';
import { isNil } from 'lodash-es';
import { Tabs } from '@app/shared/tabs.enum';
import { Icon } from '@wam/icons';
import { LayoutService } from '@app/layouts/layout.service';
import { Location } from '@angular/common';

@Component({
  selector: 'default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  @ViewChild('mobileMenu') mobileMenu: MatSidenav;
  @ViewChild('drawer') private drawer: MatSidenav;

  private destroyed$ = new Subject<void>();
  private selected: Tabs = Tabs.LOGIN;
  private displayedTabs: string[] = [];
  private firstTab: Tabs;

  readonly HIDDEN_EMAIL_DOMAIN_NAME = '@privaterelay';

  loggedInUser: User;
  logo: string;
  mobileLogo: string;
  showLoginMenu = false;

  Tabs = Tabs;
  Icon = Icon;
  Theme = Theme;

  tabPosition$ = this.store.select(
    configurationSelectors.getUserConfiguration<'top' | 'left'>('tabPosition'),
  );
  isSchoolOrDistrictAdmin$: Observable<boolean> = this.store.pipe(
    select(authenticationSelectors.getUserRoles),
    map(
      (roles: UserRole[]) =>
        roles?.includes(UserRole.SCHOOL_ADMIN) || roles?.includes(UserRole.DISTRICT_ADMIN),
    ),
  );
  isPlaylistAdmin$ = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    map(
      (user: User) =>
        user.type === UserType.ADMIN &&
        (user.roles.includes(UserRole.INTERNAL_ADMIN) ||
          user.roles.includes(UserRole.CURRICULUM_ADMIN) ||
          user.roles.includes(UserRole.ACCOUNT_ADMIN)),
    ),
  );
  isParentUser$ = this.store.pipe(
    select(settingsSelectors.getUserType),
    map((userType) => userType === UserType.PARENT),
  );
  showBell$: Observable<boolean> = this.messagesAndNotificationsTabDisplayService.shouldShow();
  bellItemCount$: Observable<number> =
    this.messagesAndNotificationsTabDisplayService.notificationCount();
  orgCode$ = this.store.select(authenticationSelectors.getOrgCode);

  blockScroll: ScrollStrategy = this.scrollStrategyOptions.block();
  tabTitles: Map<Tabs, Observable<string>> = new Map([
    [Tabs.STUDENT_HOME, this.translate.stream('Home')],
    [
      Tabs.PARENT_HOME,
      this.isParentUser$.pipe(
        mergeMap((isParent) =>
          isParent ? this.translate.stream('My Household') : this.translate.stream('Home'),
        ),
      ),
    ],
    [Tabs.TEACHER_HOME, this.translate.stream('Home')],
    [Tabs.ADMIN_HOME, this.translate.stream('Home')],
    [Tabs.ACCOUNTS, this.translate.stream('Accounts')],
    [Tabs.CONFIGURATION, this.translate.stream('Configuration')],
    [Tabs.DASHBOARDS, this.translate.stream('Dashboards')],
    [Tabs.TEACHER_DASHBOARDS, this.translate.stream('Dashboards')],
    [Tabs.REPORTS, this.translate.stream('Reports')],
    [Tabs.ORGANIZATIONS, this.translate.stream('Organizations')],
    [
      Tabs.TEACHER_USERS,
      this.isSchoolOrDistrictAdmin$.pipe(
        mergeMap((isSchoolOrDistrictAdmin) =>
          isSchoolOrDistrictAdmin
            ? this.translate.stream('Staff & Students')
            : this.translate.stream('Students'),
        ),
      ),
    ],
    [Tabs.GOALS, this.translate.stream('Courses & Goals')],
    [Tabs.INBOX, this.translate.stream('Inbox')],
    [Tabs.ACTIVITIES, this.translate.stream('Resources & Activities')],
    [Tabs.PROGRAMS, this.translate.stream('Enrollment')],
    [Tabs.USAGE, this.translate.stream('Usage')],
    [Tabs.ACHIEVEMENTS, this.translate.stream('Achievements')],
    [
      Tabs.MESSAGES,
      this.themingService.theme === Theme.UPSTART
        ? this.translate.stream('Learning Tips')
        : this.translate.stream('Messages'),
    ],
    [Tabs.MENTOR_FORMS, this.translate.stream('Forms')],
    [Tabs.CHECKLIST, this.translate.stream('Checklist')],
    [Tabs.ACTIVITY_DESIGNER, this.translate.stream('Activity Designer')],
    [Tabs.DESIGNER, this.translate.stream('Activity Designer')],
    [Tabs.ASSESSMENT_DESIGNER, this.translate.stream('Assessment Designer')],
    [Tabs.NOTIFICATIONS_MANAGER, this.translate.stream('Notifications Manager')],
    [Tabs.MESSAGES_AND_NOTIFICATIONS, this.translate.stream('Messages & Notifications')],
    [Tabs.FAMILIES, this.translate.stream('Families')],
    [Tabs.PROFILE, this.translate.stream('Profile')],
  ]);

  constructor(
    private router: Router,
    private store: Store<State>,
    private tabDisplayAggregator: TabDisplayAggregatorService,
    private authenticationGuard: AuthenticationGuard,
    private translate: TranslateService,
    private messagesAndNotificationsTabDisplayService: MessagesAndNotificationsTabDisplayService,
    public themingService: ThemingService,
    public sidenavService: SidenavService,
    private scrollStrategyOptions: ScrollStrategyOptions,
    private enrollmentWizardDialog: EnrollmentWizardDialogService,
    private layoutService: LayoutService,
    private location: Location,
  ) {}

  get messagesTabIcon(): Icon {
    return this.themingService.theme === Theme.UPSTART ? Icon.lightBulb : Icon.email;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.subscribeToDisplayedTabs();
    this.subscribeToFirstTab();
    this.subscribeToRouterEvents();
    this.setLoggedInUser();
    this.getOrgCode();
    this.initializeLogo();
    this.subscribeToSelectedTab();
  }

  logOut(): void {
    this.router.navigateByUrl('/logout');
  }

  isSelected(tab: string): boolean {
    return this.selected === Tabs[tab];
  }

  isDisplayed(tab: string): boolean {
    return this.displayedTabs.includes(tab);
  }

  notificationCount(tab: string): Observable<number> {
    return this.tabDisplayAggregator.getNotificationCountForTab(tab);
  }

  enroll(): void {
    this.enrollmentWizardDialog.enroll();
  }

  private getOrgCode(): void {
    this.store
      .pipe(
        select(authenticationSelectors.getUserRoles),
        tap((roles: UserRole[]) => {
          if (
            roles?.includes(UserRole.CLASS_ADMIN) ||
            roles?.includes(UserRole.SCHOOL_ADMIN) ||
            roles?.includes(UserRole.DISTRICT_ADMIN)
          ) {
            this.store.dispatch(getOrgCode());
          }
        }),
      )
      .subscribe();
  }

  setLoggedInUser(): void {
    this.store
      .pipe(
        select(authenticationSelectors.getCurrentUser),
        filter((user) => !isNil(user)),
        takeUntil(this.destroyed$),
      )
      .subscribe((loggedInUser: User) => (this.loggedInUser = loggedInUser));
  }

  private subscribeToDisplayedTabs(): void {
    this.tabDisplayAggregator
      .getDisplayedTabs()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((displayedTabs) => {
        const tabCountBecameZero = this.displayedTabs.length !== 0 && displayedTabs.length === 0;
        if (tabCountBecameZero) {
          this.router.navigate(['/']);
        }
        this.displayedTabs = displayedTabs;
      });
  }

  subscribeToFirstTab(): void {
    this.tabDisplayAggregator
      .getFirstTab()
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((firstTabName) => {
        this.firstTab = Tabs[firstTabName];
        if (this.location.path() === '') {
          this.selected = Tabs[firstTabName];
          this.navigateToTab(this.firstTab);
        }
      });
  }

  navigateToTab(tab: Tabs): Promise<boolean> {
    const getTabRoute = (t: Tabs) => this.router.config.find(({ data }) => data?.['tab'] === t);
    const route = getTabRoute(tab);
    if (route?.path && route?.path !== '**') {
      return this.router.navigateByUrl(route.path);
    } else {
      return Promise.resolve(false);
    }
  }

  private subscribeToRouterEvents(): void {
    const routerEvents$ = this.router.events.pipe(share());

    const navigationEnd$ = routerEvents$.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.destroyed$),
    );

    navigationEnd$
      .pipe(
        mergeMap(() => this.authenticationGuard.canActivate()),
        filter((loggedIn) => loggedIn),
        takeUntil(this.destroyed$),
        mergeMap(() => {
          this.mobileMenu?.close();
          this.drawer?.close();
          this.blockScroll?.disable();
          if (this.router.url === '/') {
            return this.navigateToTab(this.firstTab);
          }

          return EMPTY;
        }),
      )
      .subscribe();
  }

  private initializeLogo(): void {
    this.store
      .pipe(
        select(settingsSelectors.getUserType),
        map((userType) => {
          switch (userType) {
            case UserType.PARENT:
              return ['../assets/logos/mentor.png', '../assets/logos/mentor.png'];
            case UserType.UPSTART:
              return ['../assets/logos/upstart.png', '../assets/logos/upstart.png'];
            default:
              return [
                '../assets/logos/reading-academy.png',
                '../assets/logos/reading-academy-mobile.png',
              ];
          }
        }),
        take(1),
      )
      .subscribe(([logo, mobileLogo]) => {
        this.logo = logo;
        this.mobileLogo = mobileLogo;
      });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.drawer);
  }

  private subscribeToSelectedTab() {
    this.layoutService.selectedTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedTab) => (this.selected = selectedTab));
  }

  disableScroll(): void {
    this.blockScroll.enable();
  }

  enableScroll(): void {
    if (!this.mobileMenu.opened && !this.drawer.opened) {
      this.blockScroll.disable();
    }
  }

  get emailIsHidden(): boolean {
    return this.loggedInUser?.email?.includes(this.HIDDEN_EMAIL_DOMAIN_NAME);
  }

  toggleShowLoginMenu() {
    this.showLoginMenu = !this.showLoginMenu;
  }

  @HostListener('click')
  onClick() {
    this.showLoginMenu = false;
  }
}
