import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivitiesTabDisplayService } from '@app/activities/activities-tab-display.service';
import { ProfileTabDisplayService } from '@app/profile/profile-tab-display.service';
import { StudentHomeTabDisplayService } from '@app/student-home/student-home-tab-display.service';
import { ParentHomeTabDisplayService } from '@app/parent-home/parent-home-tab-display.service';
import { DashboardsTabDisplayService } from '@app/dashboards/dashboards-tab-display.service';
import { TeacherHomeTabDisplayService } from '@app/teacher-home/teacher-home-tab-display.service';
import { AdminHomeTabDisplayService } from '@app/admin-home/admin-home-tab-display.service';
import { TeacherDashboardsTabDisplayService } from '@app/teacher-dashboards/teacher-dashboards-tab-display.service';
import { ReportsTabDisplayService } from '@app/reports/reports-tab-display.service';
import { TeacherUsersTabDisplayService } from '@app/teacher-users/teacher-users-tab-display.service';
import { MentorFormsTabDisplayService } from '@app/mentor-forms/mentor-forms-tab-display.service';

import { Store } from '@ngrx/store';
import { State } from '@wam/shared';

import { AccountsTabDisplayService } from '@app/accounts/accounts-tab-display.service';
import { ConfigurationTabDisplayService } from '@app/configuration-management/configuration-tab-display.service';
import { GoalsTabDisplayService } from '@app/goals/goals-tab-display.service';
import { OrganizationsTabDisplayService } from '@app/organizations/organizations-tab-display.service';
import { ProgramsTabDisplayService } from '@app/programs/programs-tab-display.service';
import { NotificationsManagerTabDisplayService } from '@app/notifications-manager/notifications-manager-tab-display.service';
import { MessagesAndNotificationsTabDisplayService } from '@app/messages-and-notifications/messages-and-notifications-tab-display.service';
import { InboxTabDisplayService } from '@app/inbox/inbox-tab-display.service';
import { FeedDataTabDisplayService } from '@app/shared/feed-data-tab-display.service';
import { AssessmentDesignerTabDisplayService } from '@app/assessment-designer/assessment-designer-tab-display.service';
import { ParentWithClaimsTabDisplayService } from '@app/shared/parent-with-claims-tab-display.service';
import { ChecklistTabDisplayService } from '@app/checklist/checklist-tab-display.service';
import { DesignerTabDisplayService } from '@app/designer/designer-tab-display.service';
import { FamiliesTabDisplayService } from '@app/families/families-tab-display.service';
import { NewStudentHomeTabDisplayService } from '@app/new-student-home/new-student-home-tab-display.service';

@Injectable({
  providedIn: 'root',
})
export class TabDisplayAggregatorService {
  // Display service DI variable name should be === tab name
  constructor(
    private store: Store<State>,
    private ACCOUNTS: AccountsTabDisplayService,
    private ACHIEVEMENTS: FeedDataTabDisplayService,
    private ACTIVITIES: ActivitiesTabDisplayService,
    private ADMIN_HOME: AdminHomeTabDisplayService,
    private ASSESSMENT_DESIGNER: AssessmentDesignerTabDisplayService,
    private CONFIGURATION: ConfigurationTabDisplayService,
    private DASHBOARDS: DashboardsTabDisplayService,
    private DESIGNER: DesignerTabDisplayService,
    private FAMILIES: FamiliesTabDisplayService,
    private GOALS: GoalsTabDisplayService,
    private INBOX: InboxTabDisplayService,
    private MENTOR_FORMS: MentorFormsTabDisplayService,
    private MESSAGES: ParentWithClaimsTabDisplayService,
    private MESSAGES_AND_NOTIFICATIONS: MessagesAndNotificationsTabDisplayService,
    private ORGANIZATIONS: OrganizationsTabDisplayService,
    private PARENT_HOME: ParentHomeTabDisplayService,
    private STUDENT_HOME: StudentHomeTabDisplayService,
    private NEW_STUDENT_HOME: NewStudentHomeTabDisplayService,
    private PROFILE: ProfileTabDisplayService,
    private REPORTS: ReportsTabDisplayService,
    private TEACHER_DASHBOARDS: TeacherDashboardsTabDisplayService,
    private TEACHER_HOME: TeacherHomeTabDisplayService,
    private TEACHER_USERS: TeacherUsersTabDisplayService,
    private USAGE: FeedDataTabDisplayService,
    private CHECKLIST: ChecklistTabDisplayService,
    private PROGRAMS: ProgramsTabDisplayService,
    private NOTIFICATIONS_MANAGER: NotificationsManagerTabDisplayService,
  ) {}

  get properties(): string[] {
    return Object.getOwnPropertyNames(this).filter((name) => name !== 'store');
  }

  get displayedTabs$(): Observable<string[]> {
    const displayedTabs: Observable<string | null>[] = this.properties.map((name) =>
      this[name].shouldShow().pipe(map((show: boolean) => (show ? name : null))),
    );

    return combineLatest(displayedTabs).pipe(
      map((tabs) => tabs.filter((tab: string) => tab !== null)),
    );
  }

  get firstTab$(): Observable<string> {
    const firstTabs: Observable<string | null>[] = this.properties.map((name) =>
      this[name].isFirst().pipe(map((first: boolean) => (first ? name : null))),
    );

    return combineLatest(firstTabs).pipe(map((tabs) => tabs.find((tab: string) => tab !== null)));
  }

  getDisplayedTabs(): Observable<string[]> {
    return this.displayedTabs$;
  }

  getFirstTab(): Observable<string> {
    return this.firstTab$;
  }

  getNotificationCountForTab(name: string): Observable<number> {
    if (this.properties.includes(name)) {
      return this[name].notificationCount?.().pipe(map((count: number) => count ?? 0)) ?? of(0);
    }

    return of(0);
  }
}
