import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { filter, share } from 'rxjs/operators';
import { Tabs } from '@app/shared/tabs.enum';
import { isNil } from 'lodash-es';

export enum Layouts {
  NO_LAYOUT,
  DEFAULT_LAYOUT,
  STUDENT_REDESIGN_LAYOUT,
  UPSTART_NETFLIX_LAYOUT,
  UPSTART_LOGIN_LAYOUT,
}

@Injectable({ providedIn: 'root' })
export class LayoutService {
  public layout$: Subject<Layouts> = new ReplaySubject<Layouts>(1);
  public selectedTab$: Subject<Tabs> = new ReplaySubject<Tabs>();

  constructor(private router: Router) {}

  initialize() {
    this.subscribeToRouterEvents();
  }

  private subscribeToRouterEvents(): void {
    const routerEvents$ = this.router.events.pipe(share());

    const navigationEnd$ = routerEvents$.pipe(filter((event) => event instanceof NavigationEnd));
    navigationEnd$.subscribe((event: NavigationStart) => {
      if (
        event.url === '/teacher.' ||
        event.url === '/upstart.' ||
        event.url === '/mentor.' ||
        event.url === '/admin.' ||
        event.url === '/my.' ||
        event.url.startsWith('/component-library') ||
        event.url.startsWith('/logged-in') ||
        event.url.startsWith('/logout') ||
        event.url.startsWith('/eula')
      ) {
        this.nextLayout(Layouts.NO_LAYOUT);
      } else if (event.url.startsWith('/new-student-home')) {
        this.nextLayout(Layouts.STUDENT_REDESIGN_LAYOUT);
      } else if (event.url.startsWith('/user-login')) {
        this.nextLayout(Layouts.UPSTART_LOGIN_LAYOUT);
      } else if (event.url === '/landing') {
        this.nextLayout(Layouts.UPSTART_NETFLIX_LAYOUT);
      } else {
        this.nextLayout(Layouts.DEFAULT_LAYOUT);
      }
    });

    const routeRecognized$ = routerEvents$.pipe(
      filter((event) => event instanceof RoutesRecognized),
    );

    routeRecognized$.subscribe((event: RoutesRecognized) => {
      const getAssociatedTab = (e: RoutesRecognized) => e.state.root.firstChild?.data?.['tab'];

      let selectedTab = getAssociatedTab(event);

      if (!isNil(selectedTab)) {
        this.selectedTab$.next(selectedTab);
      }
    });
  }

  private nextLayout(layout: Layouts) {
    console.log('nextLayout', Layouts[layout]);
    this.layout$.next(layout);
  }
}
