import { State, TabDisplayInterface } from '@wam/shared';
import { Observable, of, skipWhile } from 'rxjs';
import { authenticationSelectors } from '@wam/authentication';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { isUpstartParent } from '@app/authentication/user.data';
import * as fromChecklist from './state/checklist.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChecklistTabDisplayService implements TabDisplayInterface {
  isUpstartParent$ = this.store.select(authenticationSelectors.getCurrentUser).pipe(
    skipWhile(isNil),
    map((user) => isUpstartParent(user)),
  );

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of<boolean>(false);
  }

  shouldShow(): Observable<boolean> {
    return this.isUpstartParent$;
  }

  notificationCount(): Observable<number> {
    return this.store.pipe(select(fromChecklist.getNbOfItemsNotChecked));
  }
}
