import { Injectable } from '@angular/core';
import { combineLatest, mergeMap, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, map, take } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';
import * as fromLanding from './state/landing.selectors';
import { isUpstartParent } from '@app/authentication/user.data';
import { Claim, State } from '@wam/shared';
import { isEmpty, isNil } from 'lodash-es';
import { GrantService } from '@app/claims/grant.service';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor(
    private store: Store<State>,
    private grantService: GrantService,
  ) {}

  isParentSelected(): Observable<boolean> {
    return this.store.pipe(select(fromLanding.isParentSelected));
  }

  isClaimSelected(): Observable<boolean> {
    return this.store
      .pipe(select(fromLanding.getSelectedClaim))
      .pipe(map((claim) => !isNil(claim)));
  }

  areThereAnyClaims(): Observable<boolean> {
    return this.grantService.loadClaims().pipe(
      map((claims: Claim[]) => !isEmpty(claims)),
      catchError(() => of(false)),
    );
  }

  mustShowLanding(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(authenticationSelectors.getCurrentUser)),
      this.isParentSelected(),
      this.isClaimSelected(),
    ]).pipe(
      map(
        ([currentUser, selected, claimSelected]) =>
          isUpstartParent(currentUser) && !selected && !claimSelected,
      ),
      take(1),
      mergeMap((showLanding) => {
        if (showLanding) {
          return this.areThereAnyClaims();
        } else {
          return of(false);
        }
      }),
    );
  }
}
