<div
  *ngIf="extendSupport"
  id="extend-support">
  <div class="help-categories">
    <h5>{{ 'Help Categories' | translate }}</h5>
    <ul>
      <li *ngFor="let category of helpCategories$ | async">
        <ng-container *ngIf="category.tags | async as tags">
          <ng-container *ngIf="category.title | async as title">
            <a
              href="#"
              (click)="
                updateSuggestions(tags);
                closeHelpCategoriesMenu();
                trackHelpdocsCategoriesClick(title);
                $event.preventDefault()
              ">
              {{ title }}
            </a>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </div>
  <a
    href="#"
    class="liveChat"
    (click)="openLiveChat(); $event.preventDefault()">
    {{ 'Support' | translate }}
  </a>
</div>
<div
  *ngIf="(showsHelp$ | async) && !extendSupport && showWidgetAnimation"
  id="extend-support-text">
  <p class="extend-support-content">
    {{ 'Access help articles and chat support here' | translate }}
  </p>
</div>

<livechat-widget
  [customerName]="liveChatVisitor?.name"
  [customerEmail]="liveChatVisitor?.email"
  [visibility]="liveChatVisibility"
  [license]="'11427433'"></livechat-widget>
