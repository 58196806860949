import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { State } from '../state/app.state';
import {
  Observable,
  Subject,
  bufferTime,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import { isNil } from 'lodash-es';
import { settingsSelectors } from '../selectors/settings.selectors';
import { UserType } from '../models/user.model';
import { HelpWidgetService } from './help-widget.service';
import { HelpdocsService } from './helpdocs.service';
import { NavigationEnd, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Tabs } from '../tabs.enum';
import { TabDisplayAggregatorService } from '../tab-display-aggregator.service';

declare global {
  interface Window {
    hdlh?: {
      brand: string;
      primary_color: string;
      suggestions: string[];
      onShow: () => void;
      onHide: () => void;
    };
  }
}

declare let Lighthouse: {
  navigate: (type, id?) => void;
  hide: () => void;
};

@Component({
  selector: 'help-widget',
  templateUrl: './help-widget.component.html',
  styleUrls: ['help-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpWidgetComponent implements OnInit, OnDestroy {
  @Input() tabTitles: Map<Tabs, Observable<string>>;
  liveChatVisitor: { name: string; email: string };
  liveChatVisibility: 'maximized' | 'minimized' | 'hidden' = 'hidden';
  extendSupport = false;
  showWidgetAnimation = false;

  showsHelp$ = combineLatest([
    this.store.pipe(select(settingsSelectors.getUserType)),
    this.router.events.pipe(bufferTime(500)),
  ]).pipe(
    map(
      ([type]) =>
        ![UserType.STUDENT, UserType.ADMIN].includes(type) &&
        !window.location.href.includes('user-login') &&
        !window.location.href.includes('eula'),
    ),
    distinctUntilChanged(),
  );

  helpCategories$ = this.tabDisplayAggregator.getDisplayedTabs().pipe(
    map((displayed) =>
      [...this.tabTitles]
        .filter(([key]) => displayed.includes(Tabs[key]))
        .map(([key, value]) => ({
          title: value,
          tags: this.helpdocsService.getTagsForTab(key),
        })),
    ),
  );

  private destroyed$ = new Subject<void>();

  constructor(
    private store: Store<State>,
    private router: Router,
    private cd: ChangeDetectorRef,
    private helpdocsService: HelpdocsService,
    private helpWidgetService: HelpWidgetService,
    private tabDisplayAggregator: TabDisplayAggregatorService,
    private angulartics2: Angulartics2,
  ) {}

  ngOnInit(): void {
    this.displayHelpWidget();
    this.hideWidgetAnimation();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackHelpdocsCategoriesClick(category: string): void {
    this.angulartics2.eventTrack.next({
      action: 'WidgetCategorySelected',
      properties: {
        category,
      },
    });
  }

  closeHelpCategoriesMenu(): void {
    this.extendSupport = false;
    this.cd.detectChanges();
    this.extendSupport = true;
    this.cd.detectChanges();
  }

  updateSuggestions(tags: string[]): void {
    window.hdlh.suggestions = tags.map((tag) => `tag:${tag}`);
    if (this.extendSupport) {
      Lighthouse.navigate('suggestions');
    }
  }

  openLiveChat(): void {
    this.liveChatVisibility = 'maximized';
    Lighthouse.hide();
  }

  private displayHelpWidget(): void {
    this.showsHelp$.pipe(takeUntil(this.destroyed$)).subscribe((showHelp) => {
      if (showHelp) {
        this.initializeLiveChat();

        this.helpWidgetService.show();
        window.hdlh.brand = '';
        window.hdlh.primary_color = '#fbfcfe';

        this.keepUpdatingSuggestions();

        window.hdlh.onShow = () => {
          this.extendSupport = true;
          this.showWidgetAnimation = false;
          this.liveChatVisibility = 'hidden';
          this.cd.detectChanges();
          Lighthouse.navigate('suggestions');
          this.angulartics2.eventTrack.next({
            action: 'WidgetOpened',
            properties: {
              category: 'HelpWidget',
            },
          });
        };

        window.hdlh.onHide = () => {
          this.extendSupport = false;
          this.cd.detectChanges();
        };
      } else {
        this.helpWidgetService.hide();
      }
    });
  }

  private initializeLiveChat(): void {
    this.store
      .pipe(
        select(authenticationSelectors.getCurrentUser),
        filter((user) => !isNil(user)),
        takeUntil(this.destroyed$),
      )
      .subscribe((user) => {
        this.liveChatVisitor = { name: user.name, email: user.email };
      });
  }

  private keepUpdatingSuggestions(): void {
    this.helpdocsService.currentTags$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tags) => this.updateSuggestions(tags));
  }

  private hideWidgetAnimation(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        if (this.router.url === '/') {
          this.showWidgetAnimation = this.helpWidgetService.shouldShowAnimation();
          if (this.showWidgetAnimation) {
            this.helpWidgetService.storeAnimationProperty();
          }
        }
      });
  }
}
