<div class="upstart-login-layout">
  <div id="site-header">
    <picture class="upstart-login-layout__header-logo">
      <source
        srcset="../assets/logos/upstart.png"
        media="(max-width: 480px)" />
      <img
        alt="logo"
        class="logo"
        src="../assets/logos/upstart.png" />
    </picture>
  </div>

  <router-outlet></router-outlet>

  <footer-component></footer-component>
</div>
