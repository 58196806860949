import { Injectable, OnDestroy } from '@angular/core';
import { State, TabDisplayInterface, UserRole } from '@wam/shared';
import { iif, Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { authenticationSelectors } from '@wam/authentication';
import { Form, FormStatus, Step, StepStatus, StepType } from '@wap/enrollment';
import { enrollmentSelectors } from './state/enrollment.selectors';

@Injectable({
  providedIn: 'root',
})
export class MentorFormsTabDisplayService implements TabDisplayInterface, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private store: Store<State>) {}

  isFirst(): Observable<boolean> {
    return of(false);
  }

  shouldShow(): Observable<boolean> {
    return this.store.pipe(
      select(authenticationSelectors.getUserRoles),
      map((roles: UserRole[]) => roles?.includes(UserRole.UPSTART_PARENT)),
      mergeMap((isUpstartParent) => iif(() => isUpstartParent, this.isFormsEnabled(), of(false))),
      takeUntil(this.destroyed$),
    );
  }

  notificationCount(): Observable<number> {
    return this.store.pipe(
      select(enrollmentSelectors.getForms),
      map((forms: Form[]) => forms?.filter((f) => f.status === FormStatus.TODO) ?? []),
      map((forms) => forms.length),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private isFormsEnabled(): Observable<boolean> {
    return this.store.pipe(
      select(enrollmentSelectors.getSteps),
      map((steps: Step[]) => {
        const formsStep = steps?.find((step: Step) => step.type === StepType.FORMS);
        return formsStep?.status === StepStatus.AVAILABLE;
      }),
    );
  }
}
