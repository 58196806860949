<div class="upstart-netflix-layout">
  <div class="upstart-netflix-layout__header">
    <picture class="upstart-netflix-layout__header__logo">
      <source
        srcset="../assets/logos/upstart.png"
        media="(max-width: 480px)" />
      <img
        alt="logo"
        class="logo"
        src="../assets/logos/upstart.png" />
    </picture>

    <cl-icon-button
      [icon]="Icon.logoutBlue"
      (click)="logOut()"
      id="logout-button">
      {{ 'Logout' | translate }}
    </cl-icon-button>
  </div>

  <router-outlet></router-outlet>

  <footer-component></footer-component>
</div>
