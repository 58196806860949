import { ApplicationRef, Component, Inject, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { filter, first, map, skipWhile, take, takeUntil } from 'rxjs/operators';
import {
  combineLatest,
  concat,
  distinctUntilChanged,
  fromEvent,
  interval,
  Observable,
  Subject,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import {
  configurationSelectors,
  settingsSelectors,
  State,
  User,
  UserRole,
  UserType,
} from '@wam/shared';
import { TranslateService } from '@ngx-translate/core';
import { MatomoInjector } from './shared/matomo-injector.service';
import { isNil, isString } from 'lodash-es';
import { isStaff } from './authentication/user.data';
import { MatomoService } from './shared/matomo.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { authenticationSelectors } from '@wam/authentication';
import { Angulartics2, Angulartics2Matomo } from 'angulartics2';
import packageJson from '../../package.json';

import { Layouts, LayoutService } from '@app/layouts/layout.service';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';

export const PROGRESS_ID = 'progress-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  isHeatMapEnabled$: Observable<boolean> = this.store.select(
    configurationSelectors.isEnabled('HEATMAP_ANALYTICS'),
  );
  showScroll$: Observable<boolean> = fromEvent(this.document, 'scroll').pipe(
    takeUntil(this.destroyed$),
    map(() => this.viewport.getScrollPosition()?.[1] > 0),
  );

  PROGRESS_ID = PROGRESS_ID;
  Layouts = Layouts;
  layout$: Observable<Layouts>;

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private angulartics2Matomo: Angulartics2Matomo,
    private matomoInjector: MatomoInjector,
    private matomoService: MatomoService,
    private update: SwUpdate,
    private snackbar: MatSnackBar,
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private viewport: ViewportScroller,
    private angulartics2: Angulartics2,
    private layoutService: LayoutService,
  ) {
    this.registerServiceWorker();
  }

  ngOnInit(): void {
    this.initializeSentry();
    this.initializeTranslations();
    this.initializeAnalytics();
    if (!isDevMode()) {
      this.checkForUpdates();
      this.initializeListeningToUpdates();
    }
    this.initializeLayout();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeSentry(): void {
    const isLocalStorageEnabled = localStorage.getItem('sentryEnabled') === 'true';
    combineLatest([
      this.store.pipe(select(configurationSelectors.isEnabled('SENTRY'))),
      this.store.pipe(select(settingsSelectors.getSentrySettings)),
      this.store
        .pipe(select(authenticationSelectors.getCurrentUser))
        .pipe(filter((user) => !isNil(user))),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([isConfigurationEnabled, settings, user]) => {
        if ((settings.environment !== 'local' && isConfigurationEnabled) || isLocalStorageEnabled) {
          Sentry.init({
            dsn: settings.dsn,
            environment: settings.environment,
            release: packageJson.version,
            tracesSampleRate: settings.tracesSampleRate,
            integrations: [Sentry.browserTracingIntegration()],
            initialScope: {
              tags: { application: user.application },
              user: {
                id: user.uuid ?? user.cognitoId,
                email: user.email,
                username: user.name,
              },
            },
            beforeSend: (event) => {
              if (isNil(event.message) || isString(event.message)) {
                return event;
              }
              return null;
            },
          });
        }
      });
  }

  private registerServiceWorker(): void {
    if (!isDevMode() && 'serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/ngsw-worker.js');
      });
    }
  }

  private initializeTranslations(): void {
    this.translate.setDefaultLang('en');
    this.store
      .select(configurationSelectors.getUserConfiguration<string>('language'))
      .pipe(skipWhile(isNil), take(1))
      .subscribe((language: string) => {
        this.translate.use(language);
      });
  }

  private initializeAnalytics(): void {
    combineLatest([
      this.store.pipe(select(authenticationSelectors.getCurrentUser)),
      this.store.pipe(select(settingsSelectors.getSettings)),
      this.store.pipe(select(configurationSelectors.getUserConfiguration<string>('language'))),
    ])
      .pipe(
        map(([user, settings, language]) => {
          const analyticsSettings = settings.analytics[this.settingsKeyFor(user, [])];
          if (analyticsSettings?.enabled) {
            this.matomoInjector.init(analyticsSettings.url, analyticsSettings.siteId);
            switch (user.type) {
              case UserType.TEACHER:
                this.angulartics2Matomo.setUsername(user.uuid);
                this.angulartics2.setUserProperties.next({
                  [`dimension${analyticsSettings.dimensions.orgIdIndex}`]: user.organization,
                  [`dimension${analyticsSettings.dimensions.schoolIdIndex}`]: user.school,
                  [`dimension${analyticsSettings.dimensions.roleIndex}`]: user.type,
                  [`dimension${analyticsSettings.dimensions.languageIndex}`]: language,
                });
                break;
              case UserType.PARENT:
                this.angulartics2Matomo.setUsername(user.cognitoId);
                this.angulartics2.setUserProperties.next({
                  [`dimension${analyticsSettings.dimensions.languageIndex}`]: language,
                });
                break;
              default:
                break;
            }
            this.angulartics2Matomo.startTracking();
            this.isHeatMapEnabled$.pipe(take(1)).subscribe((enabled) => {
              if (!enabled) {
                this.matomoService.disableHeatmapTracking();
              }
            });
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private checkForUpdates(): void {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => this.update.checkForUpdate());
  }

  private initializeListeningToUpdates(): void {
    this.update.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        const snack = this.snackbar.open('A new version is available.', 'Reload', {
          panelClass: ['snackbar'],
        });
        snack.onAction().subscribe(() => {
          window.location.reload();
        });
      });
  }

  private settingsKeyFor(user: User, userRoles: UserRole[]): string {
    if (isStaff(user)) {
      return 'staff';
    } else if (userRoles?.includes(UserRole.UPSTART_PARENT)) {
      return 'upstartParent';
    } else {
      return user?.type.toLowerCase();
    }
  }

  private initializeLayout() {
    this.layout$ = this.layoutService.layout$.pipe(distinctUntilChanged());
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }
}
