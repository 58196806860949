export enum Tabs {
  ACCOUNTS,
  ACHIEVEMENTS,
  ACTIVITIES,
  ACTIVITY_DESIGNER,
  ADMIN_HOME,
  ASSESSMENT_DESIGNER,
  CONFIGURATION,
  DASHBOARDS,
  DEFAULT,
  DESIGNER,
  FAMILIES,
  GOALS,
  INBOX,
  LANDING,
  LOGIN,
  MENTOR_FORMS,
  MESSAGES,
  MESSAGES_AND_NOTIFICATIONS,
  ORGANIZATIONS,
  PARENT_HOME,
  PROFILE,
  REPORTS,
  STUDENT_HOME,
  TEACHER_DASHBOARDS,
  TEACHER_HOME,
  TEACHER_USERS,
  USAGE,
  PROGRAMS,
  NOTIFICATIONS_MANAGER,
  NEW_STUDENT_HOME,
  CHECKLIST,
  UPSTART_LOGIN,
}
