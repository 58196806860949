<div class="layout">
  <div
    class="wrapper"
    [class.wrapper--reduced-width]="true">
    <mat-sidenav-container [class.upstart]="themingService.theme === Theme.UPSTART">
      <mat-sidenav
        [fixedTopGap]="0"
        fixedInViewport
        #mobileMenu
        mode="over"
        position="end"
        [autoFocus]="false"
        class="sidenav--right"
        [class.upstart]="themingService.theme === Theme.UPSTART"
        (opened)="disableScroll()"
        (closed)="enableScroll()">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <button
          class="header-menu-toggle__button"
          (click)="mobileMenu.toggle()">
          <cl-icon
            [icon]="Icon.cancel"
            size="44px"></cl-icon>
        </button>
      </mat-sidenav>
      <div
        class="header"
        id="site-header"
        [class.upstart]="themingService.theme === Theme.UPSTART">
        <picture class="header__logo">
          <source
            srcset="{{mobileLogo}}"
            media="(max-width: 480px)" />
          <img
            [routerLink]="['/']"
            alt="logo"
            class="logo"
            src="{{ logo }}" />
        </picture>
        <div
          class="header--nav"
          [class.upstart]="themingService.theme === Theme.UPSTART">
          <div
            class="header__logged-in-user"
            data-private
            id="logged-in-user"
            [class.upstart]="themingService.theme === Theme.UPSTART">
            <ng-container *ngTemplateOutlet="UserNameBlock"></ng-container>
          </div>
          <div
            class="header__logged-in-user__bottom"
            [class.upstart]="themingService.theme === Theme.UPSTART">
            <div
              *ngIf="orgCode$ | async as orgCode"
              class="header__logged-in-user__bottom__org-code">
              <div class="header__logged-in-user__bottom__org-code__label">
                {{ 'Org Code:' | translate }}
              </div>
              <div class="header__logged-in-user__bottom__org-code__value">{{ orgCode }}</div>
            </div>
            <cl-icon-badge
              [object]="false"
              *ngIf="showBell$ | async"
              [icon]="Icon.bellBlue"
              [notificationsCount]="bellItemCount$ | async"
              [routerLink]="['messages-and-notifications']"
              id="bell-button"></cl-icon-badge>

            @if (themingService.theme === Theme.UPSTART) {
              <div
                class="header__logged-in-user__bottom__bubble"
                (click)="toggleShowLoginMenu(); $event.stopPropagation()">
                <img
                  src="assets/parent-picture.png"
                  class="header__logged-in-user__bottom__bubble__img"
                  width="48"
                  alt="Login options" />
              </div>

              @if (showLoginMenu) {
                <div class="header__logged-in-user__bottom__drop-down-menu">
                  <cl-icon-button
                    class="header__logged-in-user__bottom__drop-down-menu__item"
                    [icon]="Icon.user"
                    link="/landing"
                    [internalRedirect]="true"
                    [size]="'28px'"
                    [openInNewTab]="false"
                    [thin]="false"
                    [disabled]="false">
                    {{ 'Change User' | translate }}
                  </cl-icon-button>
                  <cl-icon-button
                    class="header__logged-in-user__bottom__drop-down-menu__item"
                    [icon]="Icon.logoutBlue"
                    link="/logout"
                    [internalRedirect]="true"
                    [size]="'28px'"
                    [openInNewTab]="false"
                    [thin]="false"
                    [disabled]="false">
                    {{ 'Logout' | translate }}
                  </cl-icon-button>
                </div>
              }
            } @else {
              <cl-button
                (click)="logOut()"
                [thin]="true"
                id="logout-button">
                {{ 'Logout' | translate }}
              </cl-button>
            }
          </div>
        </div>
        <div
          class="header-menu-toggle"
          [class.upstart]="themingService.theme === Theme.UPSTART">
          <button
            class="header-menu-toggle__button"
            (click)="mobileMenu.toggle()">
            <cl-icon
              [icon]="mobileMenu.opened ? Icon.cancel : Icon.menuTabs"
              size="44px"></cl-icon>
          </button>
        </div>
      </div>
      <mat-sidenav-container>
        <mat-sidenav
          [fixedTopGap]="0"
          fixedInViewport
          #drawer
          mode="over"
          [autoFocus]="false"
          class="sidenav--left"
          (opened)="disableScroll()"
          (closed)="enableScroll()">
          <ng-template [cdkPortalOutlet]="sidenavService.panelPortal | async"></ng-template>
        </mat-sidenav>
        <div
          [class.left]="(tabPosition$ | async) === 'left'"
          class="page-content">
          <ng-template #header>
            <nav
              class="tabs"
              [class.upstart]="themingService.theme === Theme.UPSTART">
              <div
                class="header-mobile-menu"
                [class.upstart]="themingService.theme === Theme.UPSTART">
                <div
                  class="header__logged-in-user"
                  data-private
                  id="logged-in-user-menu"
                  [class.upstart]="themingService.theme === Theme.UPSTART">
                  <ng-container *ngTemplateOutlet="UserNameBlock"></ng-container>
                </div>
                <div
                  *ngIf="orgCode$ | async as orgCode"
                  class="header__logged-in-user__bottom__org-code">
                  <div class="header__logged-in-user__bottom__org-code__label">
                    {{ 'Org Code:' | translate }}
                  </div>
                  <div class="header__logged-in-user__bottom__org-code__value">{{ orgCode }}</div>
                </div>
              </div>
              <ul
                class="nav"
                id="nav">
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('STUDENT_HOME')"
                    [routerLink]="['student-home']"
                    [class.selected]="isSelected('STUDENT_HOME')">
                    <cl-nav-item
                      [icon]="Icon.house"
                      [newItemsCount]="notificationCount('STUDENT_HOME') | async"
                      [selected]="isSelected('STUDENT_HOME')">
                      {{ tabTitles.get(Tabs.STUDENT_HOME) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('PARENT_HOME')"
                    [routerLink]="['parent-home']"
                    [class.selected]="isSelected('PARENT_HOME')">
                    <cl-nav-item
                      [icon]="Icon.house"
                      [newItemsCount]="notificationCount('PARENT_HOME') | async"
                      [selected]="isSelected('PARENT_HOME')">
                      {{ tabTitles.get(Tabs.PARENT_HOME) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('TEACHER_HOME')"
                    [routerLink]="['teacher-home']"
                    [class.selected]="isSelected('TEACHER_HOME')">
                    <cl-nav-item
                      [icon]="Icon.house"
                      [newItemsCount]="notificationCount('TEACHER_HOME') | async"
                      [selected]="isSelected('TEACHER_HOME')">
                      {{ tabTitles.get(Tabs.TEACHER_HOME) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ADMIN_HOME')"
                    [routerLink]="['admin-home']"
                    [class.selected]="isSelected('ADMIN_HOME')">
                    <cl-nav-item
                      [icon]="Icon.house"
                      [newItemsCount]="notificationCount('ADMIN_HOME') | async"
                      [selected]="isSelected('ADMIN_HOME')">
                      {{ tabTitles.get(Tabs.ADMIN_HOME) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ACCOUNTS')"
                    [routerLink]="['accounts']"
                    [class.selected]="isSelected('ACCOUNTS')">
                    <cl-nav-item
                      [icon]="Icon.keyLabel"
                      [newItemsCount]="notificationCount('ACCOUNTS') | async"
                      [selected]="isSelected('ACCOUNTS')">
                      {{ tabTitles.get(Tabs.ACCOUNTS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('CONFIGURATION')"
                    [routerLink]="['configuration']"
                    [class.selected]="isSelected('CONFIGURATION')">
                    <cl-nav-item
                      [icon]="Icon.configure"
                      [newItemsCount]="notificationCount('CONFIGURATION') | async"
                      [selected]="isSelected('CONFIGURATION')">
                      {{ tabTitles.get(Tabs.CONFIGURATION) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('DASHBOARDS')"
                    [routerLink]="['dashboards']"
                    [class.selected]="isSelected('DASHBOARDS')">
                    <cl-nav-item
                      [icon]="Icon.dashboards"
                      [newItemsCount]="notificationCount('DASHBOARDS') | async"
                      [selected]="isSelected('DASHBOARDS')">
                      {{ tabTitles.get(Tabs.DASHBOARDS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('TEACHER_DASHBOARDS')"
                    [routerLink]="['teacher-dashboards']"
                    [class.selected]="isSelected('TEACHER_DASHBOARDS')">
                    <cl-nav-item
                      [icon]="Icon.dashboards"
                      [newItemsCount]="notificationCount('TEACHER_DASHBOARDS') | async"
                      [selected]="isSelected('TEACHER_DASHBOARDS')">
                      {{ tabTitles.get(Tabs.TEACHER_DASHBOARDS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('REPORTS')"
                    [routerLink]="['reports']"
                    [class.selected]="isSelected('REPORTS')">
                    <cl-nav-item
                      [icon]="Icon.report"
                      [newItemsCount]="notificationCount('REPORTS') | async"
                      [selected]="isSelected('REPORTS')">
                      {{ tabTitles.get(Tabs.REPORTS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ORGANIZATIONS')"
                    [routerLink]="['organizations']"
                    [class.selected]="isSelected('ORGANIZATIONS')">
                    <cl-nav-item
                      [icon]="Icon.school"
                      [newItemsCount]="notificationCount('ORGANIZATIONS') | async"
                      [selected]="isSelected('ORGANIZATIONS')">
                      {{ tabTitles.get(Tabs.ORGANIZATIONS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('TEACHER_USERS')"
                    [routerLink]="['teacher-users']"
                    [class.selected]="isSelected('TEACHER_USERS')">
                    <cl-nav-item
                      [icon]="Icon.students"
                      [newItemsCount]="notificationCount('TEACHER_USERS') | async"
                      [selected]="isSelected('TEACHER_USERS')">
                      {{ tabTitles.get(Tabs.TEACHER_USERS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('GOALS')"
                    [routerLink]="['goals']"
                    [class.selected]="isSelected('GOALS')">
                    <cl-nav-item
                      [icon]="Icon.blackboard"
                      [newItemsCount]="notificationCount('GOALS') | async"
                      [selected]="isSelected('GOALS')">
                      {{ tabTitles.get(Tabs.GOALS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('INBOX')"
                    [routerLink]="['inbox']"
                    [class.selected]="isSelected('INBOX')">
                    <cl-nav-item
                      [icon]="Icon.email"
                      [newItemsCount]="notificationCount('INBOX') | async"
                      [selected]="isSelected('INBOX')">
                      {{ tabTitles.get(Tabs.INBOX) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ACTIVITIES')"
                    [routerLink]="
                      (isPlaylistAdmin$ | async) ? ['activities', 'playlists'] : ['activities']
                    "
                    [class.selected]="isSelected('ACTIVITIES')">
                    <cl-nav-item
                      [icon]="Icon.puzzle"
                      [newItemsCount]="notificationCount('ACTIVITIES') | async"
                      [selected]="isSelected('ACTIVITIES')">
                      {{ tabTitles.get(Tabs.ACTIVITIES) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('PROGRAMS')"
                    [routerLink]="['programs']"
                    [class.selected]="isSelected('PROGRAMS')">
                    <cl-nav-item
                      [icon]="Icon.programs"
                      [newItemsCount]="notificationCount('PROGRAMS') | async"
                      [selected]="isSelected('PROGRAMS')"
                      (click)="enroll()">
                      {{ tabTitles.get(Tabs.PROGRAMS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('USAGE')"
                    [routerLink]="['usage']"
                    [class.selected]="isSelected('USAGE')">
                    <cl-nav-item
                      [icon]="Icon.usage"
                      [newItemsCount]="notificationCount('USAGE') | async"
                      [selected]="isSelected('USAGE')">
                      {{ tabTitles.get(Tabs.USAGE) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ACHIEVEMENTS')"
                    [routerLink]="['achievements']"
                    [class.selected]="isSelected('ACHIEVEMENTS')">
                    <cl-nav-item
                      [icon]="Icon.trophy"
                      [newItemsCount]="notificationCount('ACHIEVEMENTS') | async"
                      [selected]="isSelected('ACHIEVEMENTS')">
                      {{ tabTitles.get(Tabs.ACHIEVEMENTS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('MESSAGES')"
                    [routerLink]="['messages']"
                    [class.selected]="isSelected('MESSAGES')">
                    <cl-nav-item
                      [icon]="messagesTabIcon"
                      [newItemsCount]="notificationCount('MESSAGES') | async"
                      [selected]="isSelected('MESSAGES')">
                      {{ tabTitles.get(Tabs.MESSAGES) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('MENTOR_FORMS')"
                    [routerLink]="['forms']"
                    [class.selected]="isSelected('MENTOR_FORMS')">
                    <cl-nav-item
                      [icon]="Icon.form"
                      [newItemsCount]="notificationCount('MENTOR_FORMS') | async"
                      [selected]="isSelected('MENTOR_FORMS')">
                      {{ tabTitles.get(Tabs.MENTOR_FORMS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('CHECKLIST')"
                    [routerLink]="['checklist']"
                    [class.selected]="isSelected('CHECKLIST')">
                    <cl-nav-item
                      [icon]="Icon.checklistMenu"
                      [newItemsCount]="notificationCount('CHECKLIST') | async"
                      [selected]="isSelected('CHECKLIST')">
                      {{ tabTitles.get(Tabs.CHECKLIST) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ACTIVITY_DESIGNER')"
                    [routerLink]="['activity-designer']"
                    [class.selected]="isSelected('ACTIVITY_DESIGNER')">
                    <cl-nav-item
                      [icon]="Icon.activityDesigner"
                      [newItemsCount]="notificationCount('ACTIVITY_DESIGNER') | async"
                      [selected]="isSelected('ACTIVITY_DESIGNER')">
                      {{ tabTitles.get(Tabs.ACTIVITY_DESIGNER) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('DESIGNER')"
                    [routerLink]="['designer']"
                    [class.selected]="isSelected('DESIGNER')">
                    <cl-nav-item
                      [icon]="Icon.activityDesigner"
                      [newItemsCount]="notificationCount('DESIGNER') | async"
                      [selected]="isSelected('DESIGNER')">
                      {{ tabTitles.get(Tabs.DESIGNER) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('ASSESSMENT_DESIGNER')"
                    [routerLink]="['assessment-designer']"
                    [class.selected]="isSelected('ASSESSMENT_DESIGNER')">
                    <cl-nav-item
                      [icon]="Icon.assessmentDesigner"
                      [newItemsCount]="notificationCount('ASSESSMENT_DESIGNER ') | async"
                      [selected]="isSelected('ASSESSMENT_DESIGNER')">
                      {{ tabTitles.get(Tabs.ASSESSMENT_DESIGNER) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('NOTIFICATIONS_MANAGER')"
                    [routerLink]="['notifications-manager']"
                    [class.selected]="isSelected('NOTIFICATIONS_MANAGER')">
                    <cl-nav-item
                      [icon]="Icon.bellRed"
                      [newItemsCount]="notificationCount('NOTIFICATIONS_MANAGER') | async"
                      [selected]="isSelected('NOTIFICATIONS_MANAGER')">
                      {{ tabTitles.get(Tabs.NOTIFICATIONS_MANAGER) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('MESSAGES_AND_NOTIFICATIONS')"
                    [routerLink]="['messages-and-notifications']"
                    [class.selected]="isSelected('MESSAGES_AND_NOTIFICATIONS')">
                    <cl-nav-item
                      [icon]="Icon.email"
                      [newItemsCount]="notificationCount('MESSAGES_AND_NOTIFICATIONS') | async"
                      [selected]="isSelected('MESSAGES_AND_NOTIFICATIONS')">
                      {{ tabTitles.get(Tabs.MESSAGES_AND_NOTIFICATIONS) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('FAMILIES')"
                    [routerLink]="['families']"
                    [class.selected]="isSelected('FAMILIES')">
                    <cl-nav-item
                      [icon]="Icon.families"
                      [newItemsCount]="notificationCount('FAMILIES') | async"
                      [selected]="isSelected('FAMILIES')">
                      {{ tabTitles.get(Tabs.FAMILIES) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <li class="nav__item">
                  <a
                    *ngIf="isDisplayed('PROFILE')"
                    [routerLink]="['profile']"
                    [class.selected]="isSelected('PROFILE')">
                    <cl-nav-item
                      [icon]="Icon.user"
                      [newItemsCount]="notificationCount('PROFILE') | async"
                      [selected]="isSelected('PROFILE')">
                      {{ tabTitles.get(Tabs.PROFILE) | async }}
                    </cl-nav-item>
                  </a>
                </li>
                <div class="nav__item">
                  <cl-icon-button
                    [icon]="Icon.logoutBlue"
                    size="28px"
                    (click)="logOut()"
                    buttonStyle="primaryDark"
                    id="logout-button-menu">
                    {{ 'Logout' | translate }}
                  </cl-icon-button>
                </div>
              </ul>
            </nav>
          </ng-template>
          <div class="desktop-nav">
            <ng-container *ngTemplateOutlet="header"></ng-container>
          </div>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-container>
      <div
        class="footer"
        id="site-footer"
        [class.upstart]="themingService.theme === Theme.UPSTART">
        <div class="footer__copyright">Copyright © Waterford.org</div>
        <a
          class="footer__license-agreement"
          href="https://www.waterford.org/eula/"
          rel="noopener noreferrer"
          target="_blank">
          {{ 'License Agreement' | translate }}
        </a>
        <a
          class="footer__privacy-policy"
          href="https://www.waterford.org/privacy-policy/"
          rel="noopener noreferrer"
          target="_blank">
          {{ 'Privacy Policy' | translate }}
        </a>
      </div>
    </mat-sidenav-container>
  </div>
</div>

<help-widget [tabTitles]="tabTitles"></help-widget>

<ng-template #UserNameBlock>
  <ng-container *ngIf="themingService.theme !== Theme.UPSTART">
    {{ loggedInUser?.name }}
  </ng-container>

  <ng-container *ngIf="themingService.theme === Theme.UPSTART">
    <span>
      <strong>
        {{ emailIsHidden ? ('Email address is hidden' | translate) : loggedInUser?.email }}
      </strong>
    </span>
    <span>
      {{ 'Login Method' | translate }}:
      <strong>{{ loggedInUser?.providerName }}</strong>
    </span>
  </ng-container>
</ng-template>
