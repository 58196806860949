<div class="student-redesign-layout">
  <button
    tabindex="0"
    class="student-redesign-layout__user"
    (click)="openConfirmLogoutDialog()">
    <div class="student-redesign-layout__user__name">{{ preferredName ?? firstName }}</div>
    <div class="student-redesign-layout__user__icon">
      <img
        src="assets/images/avatar.png"
        alt="avatar" />
    </div>
  </button>

  <div
    class="student-redesign-layout__content"
    [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
  </div>

  <div class="student-redesign-layout__footer">
    <div class="copyright">
      {{ 'Copyright © Waterford.org' | translate }}
    </div>
    <div class="license">
      <a
        tabindex="-1"
        target="_blank"
        rel="noopener"
        href="https://www.waterford.org/wel-eula">
        {{ 'License Agreement' | translate }}
      </a>
    </div>
    <div class="privacy">
      <a
        tabindex="-1"
        target="_blank"
        rel="noopener"
        href="https://www.waterford.org/privacy-policy">
        {{ 'Privacy Policy' | translate }}
      </a>
    </div>
  </div>
</div>
