"use strict";

/* Utils */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrainingStatus = exports.EventStatus = exports.RegistrationStatus = exports.FormStatus = exports.StepType = exports.StepStatus = exports.DEFAULT = void 0;
exports.DEFAULT = 'DEFAULT';
/* Steps */
var StepStatus;
(function (StepStatus) {
  StepStatus["AVAILABLE"] = "AVAILABLE";
  StepStatus["UNAVAILABLE"] = "UNAVAILABLE";
  StepStatus["ARCHIVED"] = "ARCHIVED";
  StepStatus["COMPLETED"] = "COMPLETED";
})(StepStatus = exports.StepStatus || (exports.StepStatus = {}));
var StepType;
(function (StepType) {
  StepType["REGISTRATIONS"] = "REGISTRATIONS";
  StepType["FORMS"] = "FORMS";
  StepType["EVENTS"] = "EVENTS";
  StepType["TRAININGS"] = "TRAININGS";
})(StepType = exports.StepType || (exports.StepType = {}));
/* Forms */
var FormStatus;
(function (FormStatus) {
  FormStatus["TODO"] = "TODO";
  FormStatus["OPTIONAL"] = "OPTIONAL";
  FormStatus["UNAVAILABLE"] = "UNAVAILABLE";
  FormStatus["COMPLETED"] = "COMPLETED";
  FormStatus["ARCHIVED"] = "ARCHIVED";
})(FormStatus = exports.FormStatus || (exports.FormStatus = {}));
exports.RegistrationStatus = FormStatus;
/* Events */
var EventStatus;
(function (EventStatus) {
  EventStatus["ACTIVE"] = "ACTIVE";
  EventStatus["INACTIVE"] = "INACTIVE";
})(EventStatus = exports.EventStatus || (exports.EventStatus = {}));
/* Trainings */
var TrainingStatus;
(function (TrainingStatus) {
  TrainingStatus["STARTED"] = "STARTED";
  TrainingStatus["COMPLETED"] = "COMPLETED";
  TrainingStatus["ARCHIVED"] = "ARCHIVED";
})(TrainingStatus = exports.TrainingStatus || (exports.TrainingStatus = {}));
