import { State, TabDisplayInterface } from '@wam/shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ParentWithClaimsTabDisplayService } from '@app/shared/parent-with-claims-tab-display.service';

@Injectable({
  providedIn: 'root',
})
export class InboxTabDisplayService implements TabDisplayInterface {
  constructor(
    private store: Store<State>,
    private parentWithClaimsTabDisplayService: ParentWithClaimsTabDisplayService,
  ) {}

  isFirst(): Observable<boolean> {
    return this.parentWithClaimsTabDisplayService.isFirst();
  }

  shouldShow(): Observable<boolean> {
    const shouldShow = false;
    return this.parentWithClaimsTabDisplayService.shouldShow().pipe(
      map((isParentWithClaims) => {
        return shouldShow && isParentWithClaims;
      }),
    );
  }
}
